@import 'styles/scss/variable.scss';
@import 'styles/scss/fontSizeByRem.scss';

.workout-chart-component {
  height: fit-content;
  box-sizing: border-box;

  @media screen and (min-width: 600px) and (max-width: 1024px) {
    padding: 0 toRem(24);
  }

  .unit-and-options {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
    align-items: center;
    overflow: auto;

    button {
      border-radius: 4px !important;
    }

    @media screen and (max-width: 768px) {
      justify-content: space-around;

      >div {
        width: 48%;
      }

      padding-left: 56px;
      flex-wrap: nowrap;
    }

  }

  .stackTitleWrapper {
    @media screen and (max-width: 900px) {
      display: block;
    }
  }

  .title-container {
    display: block;
    min-height: toRem(30);
    width: 62%;

    .chart-name {
      font-family: "GRIFTER";
      font-weight: 700;
      font-size: toRem(18);
      max-width: toRem(500);

      @media screen and (max-width: 900px) {
        max-width: toRem(300);
      }
    }

    .unitWrapper {
      display: flex;
      align-items: center;
      gap: toRem(8);
      position: relative;
      bottom: toRem(3);
      margin-top: toRem(16);

      .unit-name {
        font-family: 'MonoSpec';
        font-weight: 500;
        font-size: toRem(10);
        line-height: toRem(12);
        text-transform: uppercase;
        color: $SecondaryText;
        padding: toRem(4) toRem(8);
        background: #E6E6E6;
        border-radius: toRem(100);

        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
      }
    }
  }

  .tabChartButtons {
    margin-bottom: toRem(40);

    @media screen and (min-width: 600px) and (max-width: 1024px) {
      width: fit-content !important;
    }

    .MuiButton-root {
      border: 1px solid $BorderColor !important;
      border-radius: 4px !important;
      font-family: 'Work Sans' !important;
      font-size: 12px !important;
      line-height: 19px !important;
      padding: 8px 8px !important;
      min-height: 20px !important;
      align-items: flex-start;
      -webkit-align-items: flex-start;
      -webkit-box-align: flex-start;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      -webkit-box-align: start;
      min-width: fit-content !important;
    }

    #workout-chart-option-0 {
      .MuiButton-root {
        width: auto !important;
      }
    }
    .heartRateButton {
      min-width: 96px;
      margin-right: 10px;
    }
  }

  .workoutAreaChartWrapper {
    position: relative;
    width: 100%;

    .workoutAreaChartContent {
      height: toRem(450);
      margin-left: toRem(8);
    }

    .workout-area-chart-label-y {
      font-size: 16px;
      line-height: 19px;
      position: absolute;
      top: 35%;
      transform: rotate(-90deg);
      left: -45px;
      opacity: 1;
      color: $PrimaryText !important;
      width: 100px;
      font-weight: 300;
    }

    canvas {
      margin-left: 10px;
    }

    @media screen and (max-width: 600px) {
      width: toRem(390);

      .workoutAreaChartContent {
        height: toRem(320);
        margin-left: 0;
      }

      .workout-area-chart-label-y {
        margin-bottom: 15px;
        margin-left: 12px;
        font-size: 12px;
        line-height: 14px;
        position: static;
        top: 0;
        transform: rotate(0deg);
        left: -20px;
        color: $GrayColor;
        font-weight: 600;
        opacity: 0.8;
      }
    }
  }

  .syncButtonWrapper {
    text-align: end;
    display: flex;
    gap: 8px;
    @media screen and (max-width: 900px) {
      margin: toRem(16) toRem(24) 0;
    }
  }

  .syncStravaButton {
    font-family: 'Work Sans';
    font-size: toRem(12);
    max-height: 40px;
    min-height: toRem(36);
    z-index: 99;

    @media screen and (max-width: 900px) {
      max-width: toRem(180);
      // margin-left: toRem(24);
      margin-top: toRem(16);
    }

    b {
      line-height: normal;
    }
  }
}